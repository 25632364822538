.searchbar {
  display: grid;
  grid-template-columns: 1fr auto;
  width: 100%;
  margin-bottom: 15px;
}

.searchbar__input {
  width: 100%;
  padding: 8px 15px;
  background: #ffffff;
  border: 1px solid #cccccc;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  font-family: "Source Sans Pro";
  font-size: 1rem;
  font-weight: 300;
}

.searchbar__submit {
  padding: 8px 30px;
  background: #fecb3c;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  font-family: "Source Sans Pro";
  font-size: 1rem;
  font-weight: 300;
}
